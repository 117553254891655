export const PROD = {
  apiURL: 'https://admin.r1.tsst.su/api/',
  wsURL: 'wss://admin.r1.tsst.su/ws',
};

const DEV = {
  apiURL: 'https://admin.r1.tsst.su/api/',
  wsURL: 'wss://admin.r1.tsst.su/ws',
};

const routes = {
  conf: () => {
    if (process.env.NODE_ENV === 'production') {
      return PROD;
    }
    return DEV;
  },
  uri: {

    // Admin
    /* https://admin.r1.tsst.su/JsGlAH98UgJ8pjQkr1bwKtVzx1jFuKGB/index.html#/ */

    // Accounts external

    // Accounts
    accountsExternalsTypes: 'accounts/externals/types/getlist',
    accountsGetItem: 'accounts/getitem',
    accounts: 'accounts/getlist',
    accountsSave: 'accounts/save',

    // AppConfig
    appСonfig: 'appconfig/getlist',
    appConfigSave: 'appconfig/save',

    // Auth
    auth: 'auth',

    // Cities
    citiesGetItem: 'cities/getitem',
    cities: 'cities/getlist',
    citiesSave: 'cities/save',

    // Controllers
    controllersGetItem: 'controllers/getitem',
    controllers: 'controllers/getlist',
    controllersImport: 'controllers/import',
    controllersImportConfirm: 'controllers/import/confirm',
    controllersSave: 'controllers/save',

    // Controller Modes
    controllersModes: 'controllers/modes/getlist',

    // Controller Ports
    controllerPortsCreate: 'controllers/ports/create',
    controllerPortsDelete: 'controllers/ports/delete',
    controllersPortsGetItem: 'controllers/ports/getitem',
    controllersPortsGetlist: 'controllers/ports/getlist',
    controllersPortsUpdate: 'controllers/ports/update',

    // Devices - ToDo - remove metering from naming
    devicesCollectorGroups: 'devices/collectorgroups/getlist',
    deviceGraphData: 'devices/getgraph',
    devicesGetItem: 'devices/getitem',
    devicesGetlist: 'devices/getlist',
    importDevices: 'devices/import',
    importDevicesConfirm: 'devices/import/confirm',
    devicesSave: 'devices/save',

    // Devices Drivers
    devicesDrivers: 'devices/drivers/getlist',

    // Devices Models
    devicesModelsGetItem: 'devices/models/getitem',
    devicesModels: 'devices/models/getlist',
    deviceModelSave: 'devices/models/save',

    // Dicts
    filterData: 'getdicts',
    valueDict: 'getavdicts',

    // Houses
    housesGetItem: 'houses/getitem',
    houses: 'houses/getlist',
    housesSave: 'houses/save',

    // Metering Data Units
    meteringDataUnitsCreate: 'metering/data/units/create',
    meteringDataUnitsGetItem: 'metering/data/units/getitem',
    meteringDataUnits: 'metering/data/units/getlist',
    meteringDataUnitsUpdate: 'metering/data/units/update',

    // Metering Points
    meteringPointsGetItem: 'metering/points/getitem',
    meteringPoints: 'metering/points/getlist',
    importMeteringPoints: 'metering/points/import',
    importMeteringPointsConfirm: 'metering/points/import/confirm',
    meteringPointsSave: 'metering/points/save',

    // Metering Resources Types
    meteringResourcesTypes: 'metering/resources/types/getlist',
    meteringResourcesTypesCreate: 'metering/resources/types/create',
    meteringResourcesTypesGetItem: 'metering/resources/types/getitem',
    meteringResourcesTypesUpdate: 'metering/resources/types/update',

    // Meters Data Map
    metersDataMapCreate: 'meters/data/map/create',
    metersDataMap: 'meters/data/map/getlist',
    metersDataMapUpdate: 'meters/data/map/update',

    // Meters Data Types
    metersDataTypesCreate: 'meters/data/types/create',
    metersDataTypesGetItem: 'meters/data/types/getitem',
    metersDataTypes: 'meters/data/types/getlist',
    metersDataTypesUpdate: 'meters/data/types/update',

    // Persons
    personsAddGroup: 'persons/addgroup',
    personsContactsTypes: 'persons/contacts/types/getlist',
    personsRemoveGroup: 'persons/deletegroup',
    personsGetItem: 'persons/getitem',
    persons: 'persons/getlist',
    personsSave: 'persons/save',

    // Person Contacts
    personContactsDelete: 'persons/contacts/delete',
    personContacts: 'persons/contacts/getlist',
    personContactsSave: 'persons/contacts/save',

    // Persons Forms
    personsForms: 'persons/forms/getlist',

    // PersonsGroups
    /* Get person groups for a specific person
     Запрос списка групп пользователей с учетом разрешений пользователя
     person_id - пользователь, для которого будем задавать группу.
     Если person_id = 0, то берем нового пользователя
     */
    personGroups: 'persons/groups/getgroups',
    /* Запрос списка групп пользователей */
    groups: 'persons/groups/getlist',
    groupsSave: 'persons/groups/save',

    accountsExternals: 'accounts/externals/getlist',
    accountsExternalsSave: 'accounts/externals/save',
    accountsExternalsDelete: 'accounts/externals/delete',

    // Premises
    premisesGetItem: 'premises/getitem',
    premises: 'premises/getlist',
    importPremises: 'premises/import',
    importPremisesConfirm: 'premises/import/confirm',
    premisesSave: 'premises/save',

    // Premises types
    premisesTypesGetItemCreate: 'premises/types/create',
    premisesTypesGetItem: 'premises/types/getitem',
    premisesTypes: 'premises/types/getlist',
    premisesTypesUpdate: 'premises/types/update',

    // User
    userSettings: 'settings/user/get',
    userPasswordUpdate: 'settings/user/password',
    userSessionDelete: 'settings/user/sessions/delete',
    userSessions: 'settings/user/sessions/getlist',
    userAvatarSave: 'settings/user/avatar',
    ticketsGroups: 'tickets/dicts/tickets/groups',
    userInfo: 'user-info',

    // TicketsActions
    ticketActionsCreate: 'ticket/actions/create',
    ticketActionsRemove: 'ticket/actions/delete',
    ticketActionsGetItem: 'ticket/actions/getitem',
    ticketActionsGetList: 'ticket/actions/getlist',
    ticketActionsUpdate: 'ticket/actions/update',

    // TicketsAgents
    ticketAgentsDelete: 'ticket/agents/delete',
    ticketAgentsGetList: 'ticket/agents/getlist',
    ticketAgentsSave: 'ticket/agents/create',
    ticketAgentsUpdate: 'ticket/agents/update',

    // TicketsGroups
    ticketGroupsCreate: 'ticket/groups/create',
    ticketGroupsDelete: 'ticket/groups/delete',
    ticketGroupsGetItem: 'ticket/groups/getitem',
    ticketGroupsGetList: 'ticket/groups/getlist',
    ticketGroupsUpdate: 'ticket/groups/update',

    // TicketObjectsTypes
    ticketObjectsTypesCreate: 'ticket/objects/types/create',
    ticketObjectsTypesDelete: 'ticket/objects/types/delete',
    ticketObjectsTypesGetItem: 'ticket/objects/types/getitem',
    ticketObjectsTypesGetList: 'ticket/objects/types/getlist',
    ticketObjectsTypesUpdate: 'ticket/objects/types/update',

    // tickets Priorities
    ticketPrioritiesCreate: 'ticket/priorities/create',
    ticketPrioritiesDelete: 'ticket/priorities/delete',
    ticketPrioritiesGetItem: 'ticket/priorities/getitem',
    ticketPrioritiesGetList: 'ticket/priorities/getlist',
    ticketPrioritiesUpdate: 'ticket/priorities/update',

    // Tickets States
    ticketStatesCreate: 'ticket/states/create',
    ticketStatesDelete: 'ticket/states/delete',
    ticketStatesGetItem: 'ticket/states/getitem',
    ticketStatesGetList: 'ticket/states/getlist',
    ticketStatesUpdate: 'ticket/states/update',

    // Tickets States Map
    ticketStatesMapCreate: 'ticket/states/map/create',
    ticketStatesMapDelete: 'ticket/states/map/delete',
    ticketStatesMapGetList: 'ticket/states/map/getlist',
    ticketStatesMapUpdate: 'ticket/states/map/update',

    // Skud
    /* https://skud.r1.tsst.su/JsGlAH98UgJ8pjQkr1bwKtVzx1jFuKGB/index.html */

    // Skud groups
    skudGroupsCreate: 'persons/groups/premises/skud/create',
    skudGroupsDelete: 'persons/groups/premises/skud/delete',
    skudGroupsGetlist: 'persons/groups/premises/skud/getlist',
    skudGroupPremisesUpdateList: 'persons/groups/premises/skud/update',

    // Devices
    skudDevices: 'skud/devices/list',

    // Keys
    skudKeysGetItem: 'skud/keys/getitem',
    skudKeys: 'skud/keys/getlist',
    skudKeysSave: 'skud/keys/save',

    // Premises
    skudPremises: 'skud/premises/list',

    // Media Library
    mediaFiles: 'media/getfiles',
    uploadMediaFiles: 'media/upload',
    mediaCategories: 'media/getcategories',
  },
};

export default routes;
