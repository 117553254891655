import axios from 'axios';
import routes from './routes';

const req = async (reqName, method, params) => {
  const { headers, param } = params;
  const response = await axios({
    responseType: 'json',
    method,
    url: `${routes.conf().apiURL}${routes.uri[reqName]}`,
    headers,
    data: { ...param },
  });

  return response;
};

const reqUpload = async (reqName, formData, headers) => {
  const response = await axios({
    'Content-Type': 'multipart/form-data',
    method: 'post',
    url: `${routes.conf().apiURL}${routes.uri[reqName]}`,
    headers,
    data: formData,
  });

  return response;
};

export default {
  request: (reqName, method, param) => req(reqName, method, param),
  get: (reqName, param) => req(reqName, 'get', param),
  post: (reqName, param) => req(reqName, 'post', param),
  del: (reqName, param) => req(reqName, 'delete', param),
  upload: (reqName, formData, headers) => reqUpload(reqName, formData, headers),
};
