/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const resourceTypesStore = {
  namespaced: true,
  state: {
    resourceTypesTotal: 0,
    resourceTypesList: [],
  },
  mutations: {
    SET_RESOURCE_TYPES(state, payload) {
      state.resourceTypesTotal = payload.total;
      state.resourceTypesList = payload.data;
    },
  },
  actions: {
    async getResourceTypes(context, payload) {
      const { data } = await req.post('meteringResourcesTypes', payload);
      context.commit('SET_RESOURCE_TYPES', data);
      return data;
    },
    async updateResourceType(context, payload) {
      const response = await req.post('meteringResourcesTypesUpdate', { obj: { ...payload } });
      return response;
    },
    async createResourceType(context, payload) {
      const response = await req.post('meteringResourcesTypesCreate', { obj: { ...payload } });
      return response;
    },
  },
};

export default resourceTypesStore;
