/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';
import { PROD } from '@/plugins/routes';

const createAvatarUrl = (path) => {
  const avatarTimestamp = Date.now();
  const avatarPath = `${path}?timestamp=${avatarTimestamp}`;
  return new URL(avatarPath, PROD.apiURL).href;
};

const userSettings = {
  namespaced: true,
  state: {
    activeSessionsList: [],
    userData: {},
    total: 0,
    fullName: '',
    shortName: '',
    avatarUrl: '',
  },
  getters: {
    sessionsList: (state) => {
      if (!state.activeSessionsList) {
        return [];
      }
      return state.activeSessionsList;
    },
    userData: (state) => state.userData,
    getAvatarUrl: (state) => state.avatarUrl,
    totalSessions: (state) => state.total,
    isAvatarNeedUpdate: (state) => state.updateAvatarKey,
  },
  mutations: {
    FETCH_SESSIONS(state, payload) {
      if (payload.data) {
        state.activeSessionsList = payload.data.map((session) => {
          const timeCreate = new Date(
            // eslint-disable-next-line radix
            parseInt(session.create_time) * 1000,
          ).toLocaleString('Ru-ru');
          const timeUpdate = new Date(
            // eslint-disable-next-line radix
            parseInt(session.update_time) * 1000,
          ).toLocaleString('Ru-ru');

          return { ...session, timeCreate, timeUpdate };
        });
      }
      state.total = payload.total;
    },
    FETCH_USER_DATA(state, payload) {
      state.userData = payload;
    },
    FETCH_USER_INFO(state, payload) {
      state.shortName = payload.short_name;
      state.fullName = payload.full_name;

      if (payload.avatar !== '') {
        state.avatarUrl = createAvatarUrl(payload.avatar);
      }
    },
  },
  actions: {
    async getSessionsList(context, payload) {
      const response = await req.post('userSessions', payload);
      context.commit('FETCH_SESSIONS', response.data);
    },
    async getUserData(context) {
      const response = await req.post('userSettings');
      context.commit('FETCH_USER_DATA', response.data);
    },
    async deleteSession(context, payload) {
      await req.post('userSessionDelete', { id: payload });
    },
    async updatePassword(context, payload) {
      await req.post('userPasswordUpdate', payload);
    },
    async getUserInfo(context) {
      const { data } = await req.post('userInfo');
      context.commit('FETCH_USER_INFO', data);
    },
    async updateAvatar(context, payload) {
      const data = await req.upload('userAvatarSave', payload);
      return data;
    },
  },
};

export default userSettings;
