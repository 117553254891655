<template>
  <v-avatar :size="size" color="grey lighten-3">
    <v-icon color="primary" v-if="!imageUrl">mdi-account-circle</v-icon>
    <v-img v-else :src="imageUrl" class="grey">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate size="23" color="grey lighten-5" />
        </v-row>
      </template>
    </v-img>
  </v-avatar>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseAvatar',
  props: {
    size: {
      type: String,
      default: '64',
    },
  },
  computed: {
    ...mapGetters('userSettings', ['getAvatarUrl']),
    imageUrl() {
      return this.getAvatarUrl || '';
    },
  },
};
</script>
