// eslint-disable-next-line import/no-cycle
import store from '@/store/index';
import req from '@/plugins/requests';

const websocketStore = {
  namespaced: true,

  state: {
    socket: null,
    connectionStatus: false,
    reconnectTimeout: 10000,
    meteringDeviceData: [],
    meteringDeviceParams: '',

    packetCounter: 0,
    previousRegister: 0,
    currentRegister: 0,
    firstPacketValue: null,
    losses: 0,

    deviceDescription: {},
  },

  mutations: {
    OPEN_SOCKET(state, socket) {
      state.socket = socket;
    },

    CLOSE_SOCKET(state) {
      if (state.socket) {
        state.socket.close();
      }
      state.socket = null;
    },

    SET_CONNECTION_STATUS(state, value) {
      state.connectionStatus = value;
    },

    SET_METERING_DEVICE_DATA(state, payload) {
      state.meteringDeviceData = payload;
    },

    SET_METERING_DEVICE_CHART(state, { dataType, chart }) {
      state.meteringDeviceData
        .find((item) => item.dataType === dataType)?.chart.shift();
      state.meteringDeviceData
        .find((item) => item.dataType === dataType)?.chart.push(chart);
    },

    RESET_METERING_DEVICE_DATA(state) {
      state.meteringDeviceData = [];
    },

    SET_METERING_DEVICE_PARAMS(state, payload) {
      state.meteringDeviceParams = payload;
    },
    RESET_METERING_DEVICE_PARAMS(state) {
      state.meteringDeviceParams = {};
    },

    SET_PACKAGE_LOSS_DATA(state, payload) {
      if (state.firstPacketValue === null) {
        state.firstPacketValue = payload;
        state.currentRegister = state.firstPacketValue;
        state.previousRegister = 0;
        state.losses = 0;
        state.packetCounter += 1;
      }

      if (payload < state.currentRegister) {
        state.previousRegister = state.currentRegister - payload;
        state.currentRegister = 0;
      } else {
        state.currentRegister = payload;
      }

      const idealPackets = payload - state.firstPacketValue + state.previousRegister + 1;

      if (idealPackets > state.packetCounter) {
        state.losses += (idealPackets - state.packetCounter);
      }

      state.packetCounter += 1;
    },

    RESET_PACKET_LOSS_DATA(state) {
      state.packetCounter = 0;
      state.previousRegister = 0;
      state.currentRegister = 0;
      state.firstPacketValue = null;
      state.losses = 0;
    },

    SET_DEVICE_DESC(state, payload) {
      state.deviceDescription = { ...payload };
    },
    RESET_DEVICE_DESC(state) {
      state.deviceDescription = {};
    },
  },

  actions: {
    async connectSocket({ commit, state, dispatch }, { url, isReconnect = false }) {
      const { login, token, session } = store.getters['authStore/user'];
      const authUrl = `${url}/${login}/${session}/${token}`;
      const socket = new WebSocket(authUrl);

      socket.onopen = () => {
        if (!isReconnect) {
          commit('SET_CONNECTION_STATUS', true);
        }

        commit('OPEN_SOCKET', socket);
      };

      socket.onmessage = ({ data }) => {
        const message = JSON.parse(data);

        switch (message.cmd) {
          case 'login': {
            console.log('Socket is opened and ready to interact.');
            break;
          }
          case 'msg': {
            if (Object.hasOwn(message.dat, 'text')) {
              store.commit('notificationMessages', {
                message: message.dat.text,
                type: 'success',
              });
            }
            break;
          }
          case 'meter': {
            // cri0 - check object has property
            if (!Object.hasOwn(message.dat, 'value')) {
              message.dat.value = 0.0;
            }

            const date = new Date();
            const time = new Intl.DateTimeFormat('ru', {
              timeStyle: 'medium',
            }).format(date);

            const payload = {
              dataType: message.dat.data_type,
              chart: {
                x: `${time}`,
                y: message.dat.value,
              },
            };

            commit('SET_METERING_DEVICE_CHART', payload);

            // Packet Loss
            if (message.dat.data_type === 1 && message.dat.res_type === 1) {
              commit('SET_PACKAGE_LOSS_DATA', message.dat.value);
            }
            break;
          }
          default: {
            console.log('unknown type of message: ', message.cmd);
          }
        }
      };

      socket.onclose = () => {
        if (state.connectionStatus) {
          setTimeout(() => {
            dispatch('connectSocket', { url, isReconnect: true });
          }, state.reconnectTimeout);
        }
      };
    },

    disconnectSocket({ commit }) {
      commit('SET_CONNECTION_STATUS', false);
      commit('CLOSE_SOCKET');
    },

    async subscribeMeteringDevice({ state, commit }, device) {
      commit('SET_METERING_DEVICE_PARAMS', {
        id: device.metering_point_id, // no need?
        premise: device.premise_id, // no need?
        prm: `mpoint/p${device.premise_id}/mp${device.metering_point_id}`,
      });

      const { id } = device;

      if (state.socket) {
        console.log(`subscribeMeteringDevice ${state.meteringDeviceParams.prm}`);

        const response = await req.post('deviceGraphData', { id });

        if (response.code === 20) {
          const deviceGraphData = response.data.graphs.map((item) => ({
            serial: item.serial,
            title: item.type_name,
            code: `mp${device.metering_point_id}`,
            backgroundColor: item.type_color,
            chartType: item.type_chart,
            label: item.unit_short,
            dataType: item.type_code,
          }));

          const defaultChart = [];
          const defaultChartData = {
            y: 0,
            x: '',
          };

          for (let i = 0; i < 30; i += 1) {
            defaultChart.push(defaultChartData);
          }

          commit(
            'SET_METERING_DEVICE_DATA',
            deviceGraphData.map((dataItem) => ({
              ...dataItem,
              chart: [...defaultChart],
            })),
          );

          commit('SET_DEVICE_DESC', {
            serial: response.data.device_serial,
            model: response.data.device_model,
            controllerName: response.data.controller_name,
          });

          state.socket.send(
            JSON.stringify({
              cmd: 'submp',
              prm: state.meteringDeviceParams.prm,
              dat: {},
            }),
          );
        }
      }
    },

    unsubscribeMeteringDevice({ state, commit }) {
      if (state.socket) {
        console.log(`unsubscribeMeteringDevice ${state.meteringDeviceParams.prm}`);

        state.socket.send(
          JSON.stringify({
            cmd: 'unsub',
            prm: state.meteringDeviceParams.prm,
            dat: {},
          }),
        );
      }

      commit('RESET_METERING_DEVICE_DATA');
      commit('RESET_METERING_DEVICE_PARAMS');

      commit('RESET_PACKET_LOSS_DATA');
      commit('RESET_DEVICE_DESC');
    },
  },
};

export default websocketStore;
