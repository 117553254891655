/* eslint-disable import/prefer-default-export */
export const settingsRoutes = [
  {
    path: '/settings/media-library',
    name: 'MediaLibrary',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Настройки', disabled: true },
        { text: 'Медиатека', disabled: true },
      ],
    },
    component: () => import('@/components/MediaLibrary/MediaLibrary.vue'),
  },
  {
    path: '/settings/user',
    name: 'User',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Настройки', disabled: true },
        { text: 'Профиль пользователя', disabled: true },
      ],
    },
    component: () => import('@/components/Settings/User/UserSettings.vue'),
  },
  {
    path: '/settings/preferences',
    name: 'Preferences',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Настройки', disabled: true },
        { text: 'Интерфейс', disabled: true },
      ],
    },
    component: () => import('@/components/Settings/Preferences.vue'),
  },
  {
    path: '/settings/app-config',
    name: 'Система',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Настройки', disabled: true },
        { text: 'Система', disabled: true },
      ],
    },
    component: () => import('@/components/Settings/AppConfig.vue'),
  },
];
