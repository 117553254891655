/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import personsStore from './modules/persons';
import groupsStore from './modules/groups';
import housesStore from './modules/houses';
import citiesStore from './modules/cities';
import premisesStore from './modules/premises';
import meteringPointsStore from './modules/meteringPoints';
import controllersStore from './modules/controllers';
import devicesStore from './modules/devices';
import websocketStore from './modules/websockets';
import userSettings from './modules/userSettings';
import accountsStore from './modules/accounts';
import ticketsStore from './modules/tickets';
import appConfigStore from './modules/appConfig';
import skudDevicesStore from './modules/skudDevices';
import skudPremisesStore from './modules/skudPremises';
import skudKeysStore from './modules/skudKeys';
import personContactsStore from './modules/personContacts';
import authStore from './modules/auth';
import unitsStore from './modules/units';
import metersDataTypesStore from './modules/metersDataTypes';
import metersDataMapStore from './modules/metersDataMap';
import resourceTypesStore from './modules/resouceTypes';
import premisesTypesStore from './modules/premisesTypes';
import mediaLibraryStore from './modules/mediaLibrary';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    error: {
      type: '',
      msg: '',
    },
    notificationMessages: {
      type: '',
      msg: '',
    },
  },

  getters: {
  },

  mutations: {
    error(state, err) {
      state.error = { type: err.type, msg: `${err.name}: ${err.msg}` };
    },
    notificationMessages(state, message) {
      if (message.type === 'error') {
        console.log('Error message: ', JSON.stringify(message, 0, 2));
      }
      state.notificationMessages = {
        type: message.type,
        msg: message.msg,
      };
    },
  },

  actions: {},

  modules: {
    personsStore,
    groupsStore,
    housesStore,
    citiesStore,
    premisesStore,
    meteringPointsStore,
    controllersStore,
    devicesStore,
    websocketStore,
    userSettings,
    accountsStore,
    ticketsStore,
    appConfigStore,
    skudDevicesStore,
    skudPremisesStore,
    skudKeysStore,
    personContactsStore,
    authStore,
    unitsStore,
    metersDataTypesStore,
    metersDataMapStore,
    resourceTypesStore,
    premisesTypesStore,
    mediaLibraryStore,
  },
});
