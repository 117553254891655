/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const metersDataTypesStore = {
  namespaced: true,
  state: {
    metersDataTypesTotal: 0,
    metersDataTypesList: [],
  },
  mutations: {
    SET_TYPES(state, payload) {
      state.metersDataTypesTotal = payload.total;
      state.metersDataTypesList = payload.data;
    },
  },
  actions: {
    async getMetersDataTypes(context, payload) {
      const { data } = await req.post('metersDataTypes', payload);
      context.commit('SET_TYPES', data);
      return data;
    },
    async updateMetersDataType(context, payload) {
      const response = await req.post('metersDataTypesUpdate', { obj: { ...payload } });
      return response;
    },
    async createMetersDataType(context, payload) {
      const response = await req.post('metersDataTypesCreate', { obj: { ...payload } });
      return response;
    },
  },
};

export default metersDataTypesStore;
